import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";
import { vars } from "../../../css/vars.css";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 1281 199" {...props}>
    <defs>
      <linearGradient id="textGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#800080" />
        <stop offset="0%" stopColor="#FEBF06" />
      </linearGradient>
    </defs>
    <text
      x="450"
      y="140"
      fontSize="140"
      fontWeight="bold"
      fontFamily="Arial, sans-serif"
      textAnchor="middle"
      fill="url(#textGradient)"
    >
      Web3 Infinity
    </text>
  </Svg>
  );
};

export default Logo;
