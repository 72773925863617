import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Web3 Infinity Swap',
  defaultTitle: 'Web3 Infinity Swap',
  description:
    'Cheaper and faster than Uniswap? Discover Web3 Infinity Swap, the leading DEX on BNB Smart Chain (BSC) with the best farms in DeFi and a lottery for INFTY3 Token.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@_Web3infinity',
    site: '@_web3infinity',
  },
  openGraph: {
    title: '🥞 Web3 Infinity Swap - A next evolution DeFi exchange on BNB Smart Chain (BSC)',
    description:
      'The most popular Swap on BSC by user count! Earn INFTY3 Token through yield farming or win it in the Lottery, then stake it in Infinity Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Web3 Infinity Swap), NFTs, and more, on a platform you can trust.',
    images: [{ url: 'https://web3infinity.io/hero.jpg' }],
  },
}
