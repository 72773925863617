import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("Ecosystem"),
    items: [
      {
        label: t("Trade"),
        href: "/swap",
      },
      {
        label: t("Earn"),
        href: "/farms",
      },
      {
        label: t("Whitepaper"),
        href: "https://docs.web3infinity.io/",
      },
      {
        label: t("Official Website"),
        href: "https://web3infinity.io/",
      },
      {
        label: t("Interest Calculator"),
        href: "https://web3infinity.io/interest-calculator.php",
      },
    ],
  },
  {
    label: t("Developers"),
    items: [
      {
        label: t("Github"),
        href: "https://github.com/web3infinity",
      },
    ],
  },
  {
    label: t("About"),
    items: [
      {
        label: t("Terms Of Service"),
        href: "https://policies.google.com/terms",
      },
      {
        label: t("Youtube"),
        href: "https://www.youtube.com/watch?v=wZd7yDqc7Ss",
      },
    ],
  },
];
