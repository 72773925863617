import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
<img src="https://imagedelivery.net/K65YBrkZ_s_Tz8FjXI_pUA/0274e2bb-16df-4edb-b5fe-24c26cef5300/200?v=1" ></img>
  );
};

export default Icon;
