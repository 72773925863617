import { memo, ReactNode } from 'react'
import { styled } from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import { LinkExternal, Flex, Svg, Image, Button } from '@pancakeswap/uikit'

const Wrapper = styled.div<{ $isSide: boolean }>`
  width: 100%;
  height: ${({ $isSide }) => ($isSide ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
  padding-right: ${({ $isSide }) => ($isSide ? '32px' : '0px')};
  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: space-between;
    flex-direction: ${({ $isSide }) => ($isSide ? 'column' : 'row')};
  }
`

const BubbleWrapper = styled(Flex)`
  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
    transition: background-color 0.2s, opacity 0.2s;
  }
  &:hover {
    svg {
      opacity: 0.65;
    }
  }
  &:active {
    svg {
      opacity: 0.85;
    }
  }
`

type FooterVariant = 'default' | 'side'

const Footer: React.FC<
  React.PropsWithChildren<{
    variant?: FooterVariant
    helpUrl?: string
    helpImage?: ReactNode
    externalText?: string
    externalLinkUrl?: string
  }>
> = ({
  variant = 'default',
  helpUrl,
  externalText,
  externalLinkUrl,
}) => {
  const { t } = useTranslation()
  const isSide = variant === 'side'
  return (
    <Wrapper $isSide={isSide}>
      {externalText && externalLinkUrl && (
        <Flex flexDirection={isSide ? 'column' : ['column', 'column', 'row']} alignItems="center">
   
        </Flex>
      )}
      {isSide && <Flex flexGrow={1} />}
      {helpUrl && (
        <Flex
          flexGrow={isSide ? 0 : 1}
          alignItems="center"
          width={['0%', '0%', '0%', isSide ? '0%' : 'auto']}
          justifyContent={['center', 'center', 'center', 'flex-end']}
        >

    
        </Flex>
      )}
    </Wrapper>
  )
}

export const SwapFooter = memo(Footer)
